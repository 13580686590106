import request from "@/utils/request";

// 获取路由
export const getMessageList = (params) => {
  return request({
    url: "/getMessageList",
    method: "get",
    params,
  });
};
export const updateAllMessageStatus = () => {
  return request({
    url: "/updateAllMessageStatus",
    method: "post",
  });
};
export const getUnreadMessageCountByType = () => {
  return request({
    url: "/getUnreadMessageCountByType",
    method: "get",
  });
};
export const getUnreadMessageCount = () => {
  return request({
    url: "/getUnreadMessageCount",
    method: "get",
  });
};

// 获取充值列表
export const rechargePackage = () => {
  return request({
    url: "/rechargePackage",
    method: "get",
  });
};

// 新增充值套餐
export const addRechargePackage = (data) => {
  return request({
    url: "/rechargePackage",
    method: "post",
    data,
  });
};

// 删除充值套餐
export const deleteRechargePackage = (id) => {
  return request({
    url: `/rechargePackage/${id}`,
    method: "delete",
  });
};

// 修改充值套餐
export const updateRechargePackage = (id, data) => {
  return request({
    url: `/rechargePackage/${id}`,
    method: "put",
    data,
  });
};

// 获取余额充值记录
export const rechargeRecord = (params) => {
  return request({
    url: "/rechargeRecord",
    method: "get",
    params,
  });
};
export const consumeRecord = (params) => {
  return request({
    url: "/consumeRecord",
    method: "get",
    params,
  });
};

// 余额充值
export const recharge = (data) => {
  return request({
    url: "/recharge",
    method: "post",
    data,
  });
};

// 获取优惠券列表
export const getCoupon = () => {
  return request({
    url: "/getCoupon",
    method: "get",
  });
};
export const getCouponRecharge = () => {
  return request({
    url: "/getCouponRecharge",
    method: "get",
  });
};
// 获取优惠券列表
export const couponManageList = (data) => {
  return request({
    url: "/couponManageList",
    method: "post",
    data,
  });
};

// 新增优惠券
export const addCoupon = (data) => {
  return request({
    url: "/coupon",
    method: "post",
    data,
  });
};

// 修改优惠券
export const updateCoupon = (id, data) => {
  return request({
    url: `/coupon/${id}`,
    method: "put",
    data,
  });
};

// 发放优惠券给用户
export const distributeCoupon = (data) => {
  return request({
    url: "/coupon/distribute",
    method: "post",
    data,
  });
};

// 获取所有用户
export const getAllUser = () => {
  return request({
    url: "/getAllUser",
    method: "get",
  });
};

// 获取所有套餐
export const getAllPackage = () => {
  return request({
    url: "/rechargePackages",
    method: "get",
  });
};

// couponUsers
export const couponUsers = (id) => {
  return request({
    url: "/couponUsers/" + id,
    method: "get",
  });
};

// 发布优惠券
export const publishCoupon = (id, data) => {
  return request({
    url: "/publishCoupon/" + id,
    method: "post",
    data,
  });
};

// 暂停优惠券
export const pauseCoupon = (id) => {
  return request({
    url: "/pauseCoupon/" + id,
    method: "post",
  });
};

// 恢复优惠券
export const resumeCoupon = (id) => {
  return request({
    url: "/resumeCoupon/" + id,
    method: "post",
  });
};

// 结束优惠券
export const endCoupon = (id) => {
  return request({
    url: "/endCoupon/" + id,
    method: "post",
  });
};

// 动态套餐支付宝购买
export const buyPackage = (data) => {
  return request({
    url: "/alipay/qrCode",
    method: "post",
    data,
  });
};
export const wxPackage = (data) => {
  return request({
    url: "/wxpay/qrCode",
    method: "post",
    data,
  });
};

// checkCoupon
export const checkCoupon = (data) => {
  return request({
    url: "/checkCoupon",
    method: "post",
    data,
  });
};

// 动态套餐余额购买
export const balancePay = (data) => {
  return request({
    url: "/balancePay",
    method: "post",
    data,
  });
};

// staticIps
export const staticIps = (data) => {
  return request({
    url: "/staticIps",
    method: "post",
    data,
  });
};
export const updateIpAccount = (data) => {
  return request({
    url: "/updateIpAccount",
    method: "post",
    data,
  });
};
export const getPriceByCountryCode = (params) => {
  return request({
    url: "/getPriceByCountryCode",
    method: "get",
    params,
  });
};

export const renewal = (data) => {
  return request({
    url: "/alipay/renewal",
    method: "post",
    data,
  });
};

// /system/getCountryList
export const getCountryList = () => {
  return request({
    url: "/system/getCountryList",
    method: "get",
  });
};
export const getProvinceList = (params) => {
  return request({
    url: "/system/getProvinceList",
    method: "get",
    params,
  });
};
export const getCityList = (params) => {
  return request({
    url: "/system/getCityList",
    method: "get",
    params,
  });
};

// 获取首页信息
export const getHomeInfo = () => {
  return request({
    url: "/getUserInfo",
    method: "get",
  });
};

// getFlowChart
export const getFlowChart = (params) => {
  return request({
    url: "/getFlowChart",
    method: "get",
    params,
  });
};
export const getSubFlowChart = (params) => {
  return request({
    url: "/getSubFlowChart",
    method: "get",
    params,
  });
};

export function promotionDetail() {
  return request({
    url: "/promotionDetail",
    method: "get",
  });
}
export function updateTransferStatus(data) {
  return request({
    url: "/updateTransferStatus",
    method: "post",
    data
  });
}
export function getTransferListAll(params) {
  return request({
    url: "/getTransferListAll",
    method: "get",
    params
  });
}


export function getSystemConfig() {
  return request({
    url: "/getSystemConfig",
    method: "get",
  });
}
export function updateSystemConfig(data) {
  return request({
    url: "/updateSystemConfig",
    method: "post",
    data,
  });
}
export function promotionList(params) {
  return request({
    url: "/promotionList",
    method: "get",
    params,
  });
}
export function getTransferList(params) {
  return request({
    url: "/getTransferList",
    method: "get",
    params,
  });
}
export function applyWithdraw(data) {
  return request({
    url: "/applyWithdraw",
    method: "post",
    data,
  });
}
export function myCoupon(params) {
  return request({
    url: "/myCoupon",
    method: "get",
    params,
  });
}

// 添加富文本
export function addRichText(data) {
  return request({
    url: "/addRichText",
    method: "post",
    data,
  });
}

// 修改富文本
export function updateRichText(data) {
  return request({
    url: "/updateRichText",
    method: "post",
    data,
  });
}
// 查看富文本详情
export function getRichText(id) {
  return request({
    url: "/system/getRichTextDetail?id=" + id,
    method: "get",
  });
}
// 富文本列表
export function getRichTextList(params) {
  return request({
    url: "/system/getRichTextList",
    method: "get",
    params,
  });
}
export function getRichTextListByType(params) {
  return request({
    url: "/system/getRichTextListByType",
    method: "get",
    params,
  });
}
// 删除富文本
export function deleteRichText(data) {
  return request({
    url: "/deleteRichText",
    method: "post",
    data,
  });
}

export function getOssInfo() {
  return request({
    url: "/getOssInfo",
    method: "post",
  });
}

export function adminUpdateRemark(data) {
  return request({
    url: "/adminUpdateRemark",
    method: "post",
    data,
  });
}
export function adminStaticIpList(data) {
  return request({
    url: "/adminStaticIpList",
    method: "post",
    data,
  });
}
export function getLink() {
  return request({
    url: "/getLink",
    method: "get",
  });
}
export function addLink(data) {
  return request({
    url: "/addLink",
    method: "post",
    data,
  });
}
export function deleteLink(data) {
  return request({
    url: "/deleteLink",
    method: "post",
    data,
  });
}
export function editLink(data) {
  return request({
    url: "/editLink",
    method: "post",
    data,
  });
}